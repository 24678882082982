/* Overall style for rob.rho.org.uk. */

/* Global variables */
$textColor: #444;
$highlightColor: #888;
$highlightBackgroundColor: #ddd;
$contrastColor: #aaa;

/*! The Cooper-Hewitt font family is used under the SIL Open Font License;
 *  see http://scripts.sil.org/OFL
 */

@font-face {
    font-family: 'cooper_hewitt';
    src: url('static/fonts/cooper-hewitt/cooperhewitt-light-webfont.eot');
    src: url('static/fonts/cooper-hewitt/cooperhewitt-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('static/fonts/cooper-hewitt/cooperhewitt-light-webfont.woff2') format('woff2'),
         url('static/fonts/cooper-hewitt/cooperhewitt-light-webfont.woff') format('woff'),
         url('static/fonts/cooper-hewitt/cooperhewitt-light-webfont.ttf') format('truetype'),
         url('static/fonts/cooper-hewitt/cooperhewitt-light-webfont.svg#cooper_hewittlight') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'cooper_hewitt';
    src: url('static/fonts/cooper-hewitt/cooperhewitt-lightitalic-webfont.eot');
    src: url('static/fonts/cooper-hewitt/cooperhewitt-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('static/fonts/cooper-hewitt/cooperhewitt-lightitalic-webfont.woff2') format('woff2'),
         url('static/fonts/cooper-hewitt/cooperhewitt-lightitalic-webfont.woff') format('woff'),
         url('static/fonts/cooper-hewitt/cooperhewitt-lightitalic-webfont.ttf') format('truetype'),
         url('static/fonts/cooper-hewitt/cooperhewitt-lightitalic-webfont.svg#cooper_hewittlight_italic') format('svg');
    font-weight: 300;
    font-style: italic;
}


@font-face {
    font-family: 'acorn-mode-1';
    src: url('static/fonts/acorn-mode-1/acorn-mode-1.woff');
    font-weight: 300;
    font-style: normal;
    size-adjust: 30%;
}

@mixin displayfont {
    font-family: "cooper_hewitt", helvetica,"Nimbus Sans L", sans-serif;
    font-weight: 300;
}

/* General styles */


body {
    text-align: center;
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-family: Georgia, "URW Bookman L", serif;
    line-height: 36px;
    color: $textColor;
}

h1, h2, h3, h4, h5, h6, .inline-head, .navigation, .entry-share {
    margin-top: 0px;
    @include displayfont;
    color: $textColor;
}

h1 { font-size: 48px; line-height: 72px;}
h2 { font-size: 36px; }
h3 { font-size: 24px; }
h4, h5, h6 { font-size: 18px; }

a {
    color: $highlightColor;
}

.contrast {
    color: $contrastColor;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, a.undecorated, .navigation a {
    text-decoration: none;
    color: inherit;
}

img {
    border: 0px;
}

a:hover {
    text-decoration: underline !important;
}

sup {
    line-height: 0px;
}

sup .footnote {
    padding-left: 2px;
}

.footnotes {
  border-top: 1px solid $highlightBackgroundColor;
}

.footnotes p {
    margin-top: 0px;
    margin-bottom: 0px;
}

.footnotes ol li a[rev="footnote"] {
    text-decoration: none;
}

.footnotes li {
    margin-bottom: 13px;
}

/* Layout */

#wrapper {
    overflow-x: hidden;
    max-width: 720px;
    margin: 0 auto;
    padding: 15px;
    text-align: left;
}

#header {
    text-align: center;
}

#header h1 {
    margin-bottom: 0px;
}

#header h1 img {
    width: 100%;
    max-width: 480px;
}

/* Entry */

.entry {
    margin-bottom: 24px;
    clear: both;
}

.entry-header h2 {
    margin-bottom: 4px;
}

.entry-header {
    border-top: 1px solid #ddd;
    margin-top: 10px;
    padding-top: 10px;
}

.entry:first-child .entry-header {
    border-top: none;
    margin-top: 0px;
}

.entry-footer {
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: center;
}

.date {
    @include displayfont;
    color: $contrastColor;
    font-size: small;
}

.entry-share a {
    text-decoration: none;
}

.entry-contents .hero_image {
    text-align: center;
    img {
        max-height: 360px;
    }
}

div.images-from-mail, p.image_display {
  text-align: center;
  clear: both;
}

.entry-contents img, .entry-contents iframe {
  max-width: 100%;
}

img.half-width { width: 50%; }
img.full-width { width: 100%; }

.pixelart {
    image-rendering: pixelated;
}

.compact-mac {
    max-width: 512px;
    border: 1px black solid;
    border-radius: 8px;
    image-rendering: pixelated;
}

.entry-contents .youtube_embed {
    width: 100%;
    aspect-ratio: 16 / 9;
}

.sidebar {
    width: 50%;
    float: right;
}

.entry blockquote {
  display: block;
  padding-left: 20px;
  padding-right: 20px;
  border-left: 4px solid $highlightBackgroundColor;
}

.quote-attribution {
  text-align: right;
  margin: 20px;
  font-style: italic;
}

.entry pre {
  overflow: auto;
  display: block;
  background: $highlightBackgroundColor;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: normal;
  white-space-collapse: preserve;
}

.horizontal_gallery{
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0px;

    li {
        list-style: none;
    }

    &:hover img {
        filter: grayscale(100%);
    }

    img:hover {
        margin-top: -10px;
        margin-bottom: -10px;
        border-top: $highlightBackgroundColor 10px solid;
        border-bottom: $highlightBackgroundColor 10px solid;
        filter: none;
    }
}

/* Footer */

#footer {
    @include displayfont;
    text-align: center;
    border-top: 1px solid #ddd;
    padding-top: 4px;
    padding-bottom: 4px;
}

#footer a {
    text-decoration: none;
}

#footer .prev {
    float: left;
    margin-right: 8px;
}

#footer .prev:before {
  content: "Previous: ";
  color: $contrastColor;
}

#footer .next {
    float: right;
    margin-left: 8px;
}

#footer .next:before {
  content: "Next: ";
  color: $contrastColor;
}

#footer .archive_link {
    clear: both;
    color: $contrastColor;
}

#footer .archive_link a{
  color: $textColor;
}

.colophon {
    @include displayfont;
    color: $contrastColor;
    margin: 40px 64px;
}

.entry_info > div {
    margin-bottom: 14px;
}

.entry_info .share img {
    float: left;
    margin-right: 4px;
}

.entry_info .next-and-previous .entry-link {
    margin-left: 14px;
}

.entry-list-item {
    margin-left: 14px;
    text-indent: -14px;
    margin-bottom: 4px;
}

.archive-list h3 {
    margin-top: 24px;
    margin-bottom: 4px;
}

.archive-entry {
    .title, .date {
        font-size: 18px;
    }

    .title a {
        text-decoration: none;
    }

    .date {
        float: right;
    }
}

/* Navigation */

ul.navigation {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}

.navigation .navitem {
    display: inline;
    margin: 0px 10px;
}

.navigation .navitem:before {
  content: "\2014";
  width: 24px;
  margin-left: -24px;
  text-align: center;
  display: inline-block;
  color: $contrastColor;
}

.navigation .navitem:first-child:before {
  display: none;
}

@media (max-width: 640px) {
  .navigation .navitem { 
    margin: 0px 4px;
    font-size: smaller;
  }
  .navigation .navitem:before {
    content: "|";
    width: 8px;
    margin-left: -8px;
    font-size: smaller;
  }
  .colophon {
      margin: 40px 32px;
  }

  .sidebar {
    width: 100%;
    float: none;
  }
}

@media print {
    body {
        margin: 0 2.5cm;
    }
}

/* BBC BASIC blocks */
pre.bbcbasic {
    background-color: #000;
    color: #fff;
    font-family: 'acorn-mode-1', monospace;
    line-height: 1.1;
}

/* Hidden */